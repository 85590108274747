
/**
 * @description
 * 홈 > 재능&학습 수요조사
 */

import { mapActions } from 'vuex';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeNames.js';
import { HOME_TYPE } from '@/utils/amplitudeParams.js';

import ProductCuration from '@/components/product-curation/ProductCuration.vue';

export default {
  name: 'SurveyCuration',
  components: { ProductCuration },
  props: {
    isTalent: { type: Boolean, default: false },
  },
  data: () => {
    return {
      surveyList: [],
      suggestSectionName: NEW_HOME_SECTION_NAME.SUGGESTION,
      homeType: HOME_TYPE,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const { items } = await this.getSurveyList({
        page: 1,
        limit: 10,
        isTalent: this.isTalent,
      });
      this.surveyList = items;
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home', ['getSurveyList']),
  },
};
