
/**
 * @description
 * 로드맵 큐레이션
 */

import { getPathAt } from '@/utils/path.js';
import { mapGetters } from 'vuex';

import CurationProgressSection from '@/components/common/CurationProgressSection.vue';
import Swiper from '@/components/common/swiper/CssSwiper.vue';
import CourseItem from '../course-item/CourseItem.vue';

export default {
  name: 'CourseCuration',
  components: { Swiper, CurationProgressSection, CourseItem },
  props: {
    title: { type: String, default: null },
    courseItemList: { type: Array, default: () => [] },
    to: { type: Object, default: null }, // 우측 상단에 띄울 링크 주소
    linkText: { type: String, default: '전체보기' }, // 링크 텍스트
    sectionName: { type: String, default: '' }, // click roadmap & data-ap-section에 섹션 네임을 걸고싶다면 추가
    isLoading: { type: Boolean, default: false },
    nuxtLinkBlank: { type: Boolean, default: false }, // 새 창으로 열고싶으면 true
    logParticipate: { type: Boolean, default: false }, // 참여 수업 여부 체크 로깅이 필요한 곳에서만 true
  },
  computed: {
    ...mapGetters('user/product', ['myProductList']),
  },
  methods: {
    logClickEvent({ id, title, categoryName, progress }) {
      this.logUserEvent('click_roadmap', {
        roadmap_id: id,
        roadmap_name: title,
        roadmap_category_name: categoryName,
        at: getPathAt(this.$route),
        section: this.sectionName,
        is_participate:
          this.logParticipate && progress === undefined ? false : progress > 0,
      });
    },
  },
};
