
/**
 * @description
 * 홈 > 학습 > 학년별 교육과정 아이템
 */
export default {
  name: 'GradeItem',
  props: {
    title: { type: String, default: null },
    slug: { type: String, default: null },
    icon: { type: String, default: null },
  },
  methods: {
    logClickGradeItem() {
      this.logUserEvent('click_grade_education', {
        grade: this.title.replace('<br>', ' '),
      });
    },
  },
};
