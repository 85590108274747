
/**
 * @description
 * 홈 > 학습 > 고정 큐레이션 3종
 */

import { mapGetters } from 'vuex';
import { HOME_TYPE } from '@/utils/amplitudeParams.js';

import ProductCuration from '@/components/product-curation/ProductCuration.vue';

export default {
  name: 'FixedCuration',
  components: { ProductCuration },
  data: () => {
    return {
      homeType: HOME_TYPE.EDUCATION,
    };
  },
  computed: {
    ...mapGetters('new-home/education', ['fixedCurationList']),
  },
};
