
/**
 * @description
 * 홈 화면 재능 & 학습 > 듣고 있는 수업이 포함된 로드맵 큐레이션
 */
import { ROADMAP_SECTION_NAME } from '@/utils/amplitudeNames.js';
import { getPathAt } from '@/utils/path';
import { mapActions } from 'vuex';

import CourseCuration from '@/components/course/swiper/CourseCuration.vue';

export default {
  name: 'OngoingCourses',
  components: { CourseCuration },
  data: () => {
    return {
      courses: [],
      ongoingSection: ROADMAP_SECTION_NAME.ONGOING,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const courses = await this.getMyClassCourses();

      // 만약 관련 로드맵이 없다면 얼리리턴
      if (courses.length === 0) return;

      const progress = await this.getCourseProgress({
        courseIds: courses.map(course => course.id),
      });

      this.courses = courses.map(course => {
        return {
          ...course,
          progress: progress.find(p => p.courseId === course.id)?.progress || 0,
        };
      });

      // 로드 완료 후 courses 아이템이 있다면 로깅을 찍습니다.
      this.logLoadCourseEvent();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('course', ['getMyClassCourses', 'getCourseProgress']),
    logLoadCourseEvent() {
      this.logUserEvent('load_roadmap_section', {
        at: getPathAt(this.$route),
        section: this.ongoingSection,
      });
    },
  },
};
