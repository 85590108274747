
/**
 * @description
 * 홈 > 학습 > 꾸그의 모든 수업을 만나보세요
 * 카테고리 리스트 뿌려주는 부분
 */

import { mapActions } from 'vuex';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeNames.js';

import CategoryItem from './CategoryItem.vue';

export default {
  name: 'EducationCategory',
  components: { CategoryItem },
  data: () => {
    return {
      backgroundMobile: `${process.env.CLOUD_FRONT}/images/landing/education-mobile.jpg`,
      backgroundDesktop: `${process.env.CLOUD_FRONT}/images/landing/education-desktop.jpg`,
      categoryList: [],
      sectionName: NEW_HOME_SECTION_NAME.EDUCATION_CATEGORY,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.categoryList = await this.getCategoryList();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home/education', ['getCategoryList']),
  },
};
