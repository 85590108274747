import { render, staticRenderFns } from "./ReviewCuration.vue?vue&type=template&id=7733300a"
import script from "./ReviewCuration.vue?vue&type=script&lang=js"
export * from "./ReviewCuration.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReviewItem: require('/home/runner/work/gguge.web/gguge.web/components/review/ReviewItem.vue').default})
