
/**
 * @description
 * 홈 > 학습 > 단기 커리큘럼 큐레이션
 */

import { mapActions } from 'vuex';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeNames.js';
import { HOME_TYPE } from '@/utils/amplitudeParams.js';

import ProductCuration from '@/components/product-curation/ProductCuration.vue';

export default {
  name: 'ShortCurriculumCuration',
  components: { ProductCuration },
  data: () => {
    return {
      shortCurriculumList: [],
      educationHomeType: HOME_TYPE.EDUCATION,
      shortTermSectionName: NEW_HOME_SECTION_NAME.SHORT_TERM,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.shortCurriculumList = await this.getCurriculumCuration({
        curationType: 'SHORT',
      });
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home/education', ['getCurriculumCuration']),
  },
};
