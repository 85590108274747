
/**
 * @description
 * 홈 > 학습 > 목표달성 추천 로드맵
 */

import { ROADMAP_SECTION_NAME } from '@/utils/amplitudeNames.js';
import { getPathAt } from '@/utils/path';
import { mapActions } from 'vuex';

import CourseCuration from '@/components/course/swiper/CourseCuration.vue';

export default {
  name: 'EducationCourseCuration',
  components: { CourseCuration },
  data: () => {
    return {
      courses: [],
      mainSection: ROADMAP_SECTION_NAME.MAIN,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.courses = await this.getCourseCuration();

      // 로드 완료 후 courses 아이템이 있다면 로깅을 찍습니다.
      this.courses.length > 0 && this.logLoadCourseEvent();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('course', ['getCourseCuration']),
    logLoadCourseEvent() {
      this.logUserEvent('load_roadmap_section', {
        at: getPathAt(this.$route),
        section: this.mainSection,
      });
    },
  },
};
