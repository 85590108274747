
/**
 * @description
 * 홈 > 재능 > 후회없는 첫 구매 선택 큐레이션
 */

import { mapActions } from 'vuex';
import { HOME_TYPE } from '@/utils/amplitudeParams.js';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeNames.js';

import ProductCuration from '@/components/product-curation/ProductCuration.vue';

export default {
  name: 'MostPurchased',
  components: { ProductCuration },
  data: () => {
    return {
      mostPurchasedList: [],
      firstPickSectionName: NEW_HOME_SECTION_NAME.FIRST_PICK,
      firstPickHomeType: HOME_TYPE.TALENT,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.mostPurchasedList = await this.getMostPurchasedByNewUser();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home/talent', ['getMostPurchasedByNewUser']),
  },
};
