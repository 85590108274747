
/**
 * @description
 * 홈 > 학습 > 꾸그의 모든 수업을 만나보세요 카테고리의 아이템
 */

import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeNames.js';

export default {
  name: 'CategoryItem',
  props: {
    title: { type: String, default: null },
    id: { type: Number, default: 0 },
  },
  methods: {
    logClickCategory() {
      this.logUserEvent('click_category', {
        main_category_id: this.id,
        main_category_name: this.title,
        section: NEW_HOME_SECTION_NAME.EDUCATION_CATEGORY,
      });
    },
  },
};
