
/**
 * @description
 * 상품 리스트를 보여주는 큐레이션
 */

import { mapGetters } from 'vuex';
import { getPathAt } from '@/utils/path.js';

import Swiper from '@/components/common/swiper/CssSwiper.vue';
import CurationProgressSection from '@/components/common/CurationProgressSection.vue';
import ProductItem from '@/components/product-item/ProductItem.vue';

export default {
  name: 'ProductCuration',
  components: { Swiper, CurationProgressSection, ProductItem },
  props: {
    title: { type: String, default: null },
    productList: { type: Array, default: () => [] },
    showTagList: { type: Boolean, default: true }, // 태그 부분을 보여줄 것인지 여부. 디폴트는 true
    showPrice: { type: Boolean, default: false }, // 상품 각각의 가격 섹션을 보여줄 것인지 여부. 디폴트는 false
    showCumulativeCount: { type: Boolean, default: false }, // 누적 선택 갯수 보여줄지 여부. 디폴트는 false
    showRating: { type: Boolean, default: true }, // 별점과 찜하기를 보여줄것인지 여부. 디폴트는 true
    useClassCountTag: { type: Boolean, default: false }, // tagList에 횟수 태그를 넣을것인지 여부. 디폴트는 false
    to: { type: Object, default: null }, // 우측 상단에 띄울 링크 주소
    linkText: { type: String, default: '전체보기' }, // 링크 텍스트

    sectionName: { type: String, default: '' }, // click product에 섹션 네임을 걸고싶다면 추가
    isLoading: { type: Boolean, default: false },

    nuxtLinkBlank: { type: Boolean, default: false }, // 새 창으로 열고싶으면 true
    newCuration: { type: Boolean, default: false }, // 신규 큐레이션...

    homeType: { type: String, default: null }, // 홈에서 사용중인 큐레이션의 경우 프로퍼티로 내려주면 click_product이벤트에 찍히게 해줍니다.
  },
  computed: {
    ...mapGetters('user/product', ['myProductList']),
  },
  methods: {
    logEvent(product) {
      const { id, ratingCount, classType, teacher, productStatus } = product;
      const isEnrolled =
        this.$auth.loggedIn && id > -1 && this.myProductList.includes(id);

      this.logUserEvent('click_product', {
        section: this.sectionName,
        product_id: id,
        at: getPathAt(this.$route),
        class_review_cnt: ratingCount,
        teacher_review_cnt: teacher && teacher.ratingCount,
        teacher_id: teacher && teacher.id,
        class_type: classType,
        is_enrolled: isEnrolled,
        product_status: productStatus,
        home_type: this.homeType,
      });
    },
  },
};
